







import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'DemoDetails',
})
export default class DemoDetails extends Vue {
  clients = null;
  // HOOKS
  mounted() {
    const url = `http://localhost:8080/v1/clients/${this.$route.params.id}`;
    axios.get(url).then((response) => (this.clients = response.data.data));
  }
}
